/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.144.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ErrorRest } from '../models';
// @ts-ignore
import type { UnterAufgabeNeuRest } from '../models';
// @ts-ignore
import type { UnterAufgabeRest } from '../models';
/**
 * UnterAufgabeResourceApi - axios parameter creator
 * @export
 */
export const UnterAufgabeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b><p>Endpunkt zum Hinzufügen einer Unteraufgabe.<br><br> Mit diesem Endpunkt wird einer Aufgabe eine neue Unteraufgabe hinzugefügt.<br> Die Unteraufgabe wird ans Ende der Unteraufgaben der Aufgabe hinzugefügt.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Erzeugt eine neue UnterAufgabe.
         * @param {UnterAufgabeNeuRest} unterAufgabeNeuRest Liste der ActionItems die hinzugefügt werden sollen.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} aufgabenID ID der Aufgabe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnteraufgabe: async (unterAufgabeNeuRest: UnterAufgabeNeuRest, planID: string, aufgabenID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unterAufgabeNeuRest' is not null or undefined
            assertParamExists('createUnteraufgabe', 'unterAufgabeNeuRest', unterAufgabeNeuRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('createUnteraufgabe', 'planID', planID)
            // verify required parameter 'aufgabenID' is not null or undefined
            assertParamExists('createUnteraufgabe', 'aufgabenID', aufgabenID)
            const localVarPath = `/verfahren/{planID}/aufgaben/{aufgabenID}/unteraufgaben`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"aufgabenID"}}`, encodeURIComponent(String(aufgabenID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unterAufgabeNeuRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Unteraufgabe gelöscht.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Löschen einer Unteraufgabe.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} unteraufgabeID ID der zu löschenden Unteraufgabe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnteraufgabe: async (planID: string, unteraufgabeID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('deleteUnteraufgabe', 'planID', planID)
            // verify required parameter 'unteraufgabeID' is not null or undefined
            assertParamExists('deleteUnteraufgabe', 'unteraufgabeID', unteraufgabeID)
            const localVarPath = `/verfahren/{planID}/unteraufgaben/{unteraufgabeID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"unteraufgabeID"}}`, encodeURIComponent(String(unteraufgabeID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird für eine Unteraufgabe ein neuer Durchgang angelegt.<br> Basis hierfür ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt um für eine Unteraufgabe einen neuen Durchgang anzulegen.
         * @param {string} planID PlanID vom Verfahren.
         * @param {string} unterAufgabenID ID der Unteraufgabe für den ein Durchgang angelegt werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neuerDurchgangUnterAufgabe: async (planID: string, unterAufgabenID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('neuerDurchgangUnterAufgabe', 'planID', planID)
            // verify required parameter 'unterAufgabenID' is not null or undefined
            assertParamExists('neuerDurchgangUnterAufgabe', 'unterAufgabenID', unterAufgabenID)
            const localVarPath = `/verfahren/{planID}/unteraufgaben/{unterAufgabenID}/neuenDurchgangAnlegen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"unterAufgabenID"}}`, encodeURIComponent(String(unterAufgabenID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnterAufgabeResourceApi - functional programming interface
 * @export
 */
export const UnterAufgabeResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnterAufgabeResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Endpunkt zum Hinzufügen einer Unteraufgabe.<br><br> Mit diesem Endpunkt wird einer Aufgabe eine neue Unteraufgabe hinzugefügt.<br> Die Unteraufgabe wird ans Ende der Unteraufgaben der Aufgabe hinzugefügt.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Erzeugt eine neue UnterAufgabe.
         * @param {UnterAufgabeNeuRest} unterAufgabeNeuRest Liste der ActionItems die hinzugefügt werden sollen.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} aufgabenID ID der Aufgabe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUnteraufgabe(unterAufgabeNeuRest: UnterAufgabeNeuRest, planID: string, aufgabenID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnterAufgabeRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUnteraufgabe(unterAufgabeNeuRest, planID, aufgabenID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UnterAufgabeResourceApi.createUnteraufgabe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Unteraufgabe gelöscht.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Löschen einer Unteraufgabe.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} unteraufgabeID ID der zu löschenden Unteraufgabe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUnteraufgabe(planID: string, unteraufgabeID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUnteraufgabe(planID, unteraufgabeID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UnterAufgabeResourceApi.deleteUnteraufgabe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird für eine Unteraufgabe ein neuer Durchgang angelegt.<br> Basis hierfür ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt um für eine Unteraufgabe einen neuen Durchgang anzulegen.
         * @param {string} planID PlanID vom Verfahren.
         * @param {string} unterAufgabenID ID der Unteraufgabe für den ein Durchgang angelegt werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neuerDurchgangUnterAufgabe(planID: string, unterAufgabenID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnterAufgabeRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neuerDurchgangUnterAufgabe(planID, unterAufgabenID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UnterAufgabeResourceApi.neuerDurchgangUnterAufgabe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UnterAufgabeResourceApi - factory interface
 * @export
 */
export const UnterAufgabeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnterAufgabeResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Endpunkt zum Hinzufügen einer Unteraufgabe.<br><br> Mit diesem Endpunkt wird einer Aufgabe eine neue Unteraufgabe hinzugefügt.<br> Die Unteraufgabe wird ans Ende der Unteraufgaben der Aufgabe hinzugefügt.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Erzeugt eine neue UnterAufgabe.
         * @param {UnterAufgabeNeuRest} unterAufgabeNeuRest Liste der ActionItems die hinzugefügt werden sollen.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} aufgabenID ID der Aufgabe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnteraufgabe(unterAufgabeNeuRest: UnterAufgabeNeuRest, planID: string, aufgabenID: string, options?: RawAxiosRequestConfig): AxiosPromise<UnterAufgabeRest> {
            return localVarFp.createUnteraufgabe(unterAufgabeNeuRest, planID, aufgabenID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Unteraufgabe gelöscht.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Löschen einer Unteraufgabe.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} unteraufgabeID ID der zu löschenden Unteraufgabe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnteraufgabe(planID: string, unteraufgabeID: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUnteraufgabe(planID, unteraufgabeID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird für eine Unteraufgabe ein neuer Durchgang angelegt.<br> Basis hierfür ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt um für eine Unteraufgabe einen neuen Durchgang anzulegen.
         * @param {string} planID PlanID vom Verfahren.
         * @param {string} unterAufgabenID ID der Unteraufgabe für den ein Durchgang angelegt werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neuerDurchgangUnterAufgabe(planID: string, unterAufgabenID: string, options?: RawAxiosRequestConfig): AxiosPromise<UnterAufgabeRest> {
            return localVarFp.neuerDurchgangUnterAufgabe(planID, unterAufgabenID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnterAufgabeResourceApi - object-oriented interface
 * @export
 * @class UnterAufgabeResourceApi
 * @extends {BaseAPI}
 */
export class UnterAufgabeResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b><p>Endpunkt zum Hinzufügen einer Unteraufgabe.<br><br> Mit diesem Endpunkt wird einer Aufgabe eine neue Unteraufgabe hinzugefügt.<br> Die Unteraufgabe wird ans Ende der Unteraufgaben der Aufgabe hinzugefügt.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
     * @summary Erzeugt eine neue UnterAufgabe.
     * @param {UnterAufgabeNeuRest} unterAufgabeNeuRest Liste der ActionItems die hinzugefügt werden sollen.
     * @param {string} planID PlanID vom Verfahren
     * @param {string} aufgabenID ID der Aufgabe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnterAufgabeResourceApi
     */
    public createUnteraufgabe(unterAufgabeNeuRest: UnterAufgabeNeuRest, planID: string, aufgabenID: string, options?: RawAxiosRequestConfig) {
        return UnterAufgabeResourceApiFp(this.configuration).createUnteraufgabe(unterAufgabeNeuRest, planID, aufgabenID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Unteraufgabe gelöscht.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
     * @summary Endpunkt zum Löschen einer Unteraufgabe.
     * @param {string} planID PlanID vom Verfahren
     * @param {string} unteraufgabeID ID der zu löschenden Unteraufgabe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnterAufgabeResourceApi
     */
    public deleteUnteraufgabe(planID: string, unteraufgabeID: string, options?: RawAxiosRequestConfig) {
        return UnterAufgabeResourceApiFp(this.configuration).deleteUnteraufgabe(planID, unteraufgabeID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird für eine Unteraufgabe ein neuer Durchgang angelegt.<br> Basis hierfür ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
     * @summary Endpunkt um für eine Unteraufgabe einen neuen Durchgang anzulegen.
     * @param {string} planID PlanID vom Verfahren.
     * @param {string} unterAufgabenID ID der Unteraufgabe für den ein Durchgang angelegt werden soll.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnterAufgabeResourceApi
     */
    public neuerDurchgangUnterAufgabe(planID: string, unterAufgabenID: string, options?: RawAxiosRequestConfig) {
        return UnterAufgabeResourceApiFp(this.configuration).neuerDurchgangUnterAufgabe(planID, unterAufgabenID, options).then((request) => request(this.axios, this.basePath));
    }
}

