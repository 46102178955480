/**
 * @typedef {import('../services/open-api/models/code-verfahrensschritte.js').CodeVerfahrensschritte } CodeVerfahrensschritte
 */

import moment from "moment";
import objectHash from "object-hash";
import { defineStore } from "pinia";

import { getPlanID } from "@/router/services";
import { openAPIFactory } from "@/services/open-api.js";
import { getProceedingConfiguration } from "@/services/proceedings.js";
import { useAppStore } from "@/stores/app.js";
import { useAppointmentsStore } from "@/stores/appointments.js";
import { useFundamentalStore } from "@/stores/fundamental.js";

export const useProceedingsStore = defineStore("proceedings", {
  namespaced: true,
  state: () => ({
    proceedingConfiguration: {},
    proceedingContainer: {},
    subscribedProceedingIDs: [],
    searchResults: [],
    protocolsData: [],
    errors: new Set(),
  }),
  actions: {
    /**
     * Performs a search of proceedings matching given criteria.
     * @param {Object} searchOptions - Criteria defined for the search.
     * @param {Boolean} updateSearchResults - whether to update searchResults" state
     * @return {Promise}
     *   Returns a promise that gets resolved or rejected based upon the response of the backend.
     */
    searchProceedings(searchOptions, updateSearchResults = true) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "searchProceedings",
        text: "Einen Moment bitte, die Suche wird ausgeführt.",
      });

      const optionsBlueprint = {
        name: undefined,
        plannameArbeitstitel: undefined,
        ehemaligerPlanname: undefined,
        bueroId: undefined,
        besitzer: undefined,
        codePlanstatus: undefined,
        codeZustaendigkeit: undefined,
        codeBezirk: undefined,
        codeGebietseinheit: undefined,
        codePlanart: undefined,
        codeVerfahrenssteuerung: undefined,
        codeVerfahrensstand: undefined,
        codeGebaeudeart: undefined,
        codeWettbewerbsart: undefined,
        options: undefined,
      };
      const definedOptions = { ...optionsBlueprint, ...searchOptions };
      const {
        name,
        plannameArbeitstitel,
        ehemaligerPlanname,
        besitzer,
        bueroId,
        codePlanstatus,
        codeZustaendigkeit,
        codeBezirk,
        codeGebietseinheit,
        codePlanart,
        codeVerfahrenssteuerung,
        codeVerfahrensstand,
        codeGebaeudeart,
        codeWettbewerbsart,
        options,
      } = definedOptions;

      return new Promise((resolve, reject) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .einfacheSucheVerfahren(
            // submit all values even if they are 'undefined' (not relevant in WeSIA)
            name,
            plannameArbeitstitel,
            ehemaligerPlanname,
            bueroId,
            besitzer,
            codePlanstatus,
            codeZustaendigkeit,
            codeBezirk,
            codeGebietseinheit,
            codePlanart,
            codeVerfahrenssteuerung,
            codeVerfahrensstand,
            codeGebaeudeart,
            codeWettbewerbsart,
            options,
          )
          .then((response) => {
            if (updateSearchResults) this.searchResults = response.data;

            appStore.hidePageLoadingIndicator("searchProceedings");

            resolve(response.data);
          })
          .catch((error) => {
            appStore.hidePageLoadingIndicator("searchProceedings");

            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Die Suche nach Verfahren ist fehlgeschlagen!",
            });

            reject();
          });
      });
    },
    /**
     * Loads all proceedings the current user is subscribed to and caches the data.
     * @returns {void}
     */
    loadSubscribedProceedings() {
      const appStore = useAppStore();

      if (appStore.resolved["/verfahren"] === false) {
        appStore.showPageLoadingIndicator({
          id: "loadSubscribedProceedings",
          text: "Moment. Ihre Verfahren werden geladen.",
        });

        openAPIFactory
          .verfahrenResourceApiFactory()
          .getAllVerfahren()
          .then((response) => {
            appStore.hidePageLoadingIndicator("loadSubscribedProceedings");

            if (response.data.length) {
              response.data.forEach((proceeding) => {
                if (proceeding.planstatus.code !== "0110") {
                  this.cacheProceeding({
                    proceeding,
                    isStub: true,
                    subscribed: true,
                  });
                }
              });
            }

            appStore.resolved["/verfahren"] = true;
          })
          .catch((error) => {
            appStore.hidePageLoadingIndicator("loadSubscribedProceedings");

            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Laden der Übersicht fehlgeschlagen!",
            });
          });
      }
    },
    /**
     * Loads all proceeding IDs the current user is subscribed to and caches the data.
     * @returns {void}
     */
    loadSubscribedProceedingIDs() {
      const appStore = useAppStore();

      if (appStore.resolved["/nutzer/verfahren"] === false) {
        new Promise((resolve, reject) => {
          appStore.showPageLoadingIndicator({
            id: "bootstrap",
            text: "Moment. Die Anwendung wird geladen.",
          });

          openAPIFactory
            .nutzerResourceApiFactory()
            .getNutzerVerfahren()
            .then((response) => {
              if (response.data) {
                this.subscribedProceedingIDs = response.data.map((entry) => entry.planID);

                appStore.resolved["/nutzer/verfahren"] = true;
              }

              resolve();
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Laden der Übersicht fehlgeschlagen!",
              });

              reject();
            })
            .finally(() => {
              appStore.hidePageLoadingIndicator("bootstrap");
            });
        });
      }
    },
    /**
     * Loads a specific proceeding configuration
     * @param {String, undefined} planID
     * @returns {Promise}
     */

    async loadConfigurationByID(planID) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "loadProceedingConfiguration",
        text: "Einen Moment bitte, das gewünschte Verfahren wird geladen.",
      });

      return new Promise((resolve, reject) => {
        getProceedingConfiguration(planID)
          .then((configuration) => {
            this.proceedingConfiguration[planID] = configuration;

            resolve(configuration);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Laden der Verfahrenskonfiguration fehlgeschlagen!",
            });

            reject(error);
          })
          .finally(() => {
            appStore.hidePageLoadingIndicator("loadProceedingConfiguration");
          });
      });
    },
    /**
     * Loads a specific proceeding and caches the data.
     * @param {String, Object, undefined} proceedingID
     *   If no ID is given the ID is derived from the path, if the ID is provided encapsulated
     *   within an object, a forced reload is done.
     * @returns {Promise}
     */
    loadProceedingByID(proceedingID) {
      const appStore = useAppStore();
      let forceReload;

      if (proceedingID && proceedingID.constructor.name === "String") {
        forceReload = false;
      } else if (proceedingID) {
        proceedingID = proceedingID.planID;
        forceReload = true;
      } else {
        proceedingID = getPlanID();
        forceReload = false;
      }

      if (
        this.proceedingContainer[proceedingID] === undefined ||
        this.proceedingContainer[proceedingID].loading !== undefined ||
        this.proceedingContainer[proceedingID].isStub ||
        forceReload
      ) {
        appStore.showPageLoadingIndicator({
          id: "loadProceeding",
          text: "Einen Moment bitte, das gewünschte Verfahren wird geladen.",
        });

        // workaround, since "getVerfahrenDetail" currently does not provide "preisgerichtDatum"
        const preisgerichtDatum =
          this.proceedingContainer[proceedingID]?.workingCopy?.preisgerichtDatum;

        this.proceedingContainer[proceedingID] = {
          loading: true,
          subscribed:
            this.proceedingContainer[proceedingID] !== undefined &&
            this.proceedingContainer[proceedingID].subscribed,
        };

        return new Promise((resolve, reject) => {
          openAPIFactory
            .verfahrenResourceApiFactory()
            .getVerfahrenDetail(proceedingID)
            .then((response) => {
              const loadedProceeding = { ...response.data, preisgerichtDatum: preisgerichtDatum };

              this.cacheProceeding({
                proceeding: loadedProceeding,
                isStub: false,
                subscribed: this.checkIfProceedingIsSubscribed(proceedingID),
              });

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Laden des Verfahrens fehlgeschlagen!",
              });

              reject(error);
            })
            .finally(() => {
              appStore.hidePageLoadingIndicator("loadProceeding");
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Loads all parallel proceedings of a specific proceeding.
     * @param {String} proceedingID
     *   ID of the plan to load all parallel proceedings
     *   currently given as object, later given as String (see comment below).
     * @returns {Promise<Array>}
     *   Array of parallel proceedings.
     */
    loadParallelProceedingsForID(proceedingID) {
      proceedingID = proceedingID === undefined ? getPlanID() : proceedingID;

      const appStore = useAppStore();
      const appointmentsStore = useAppointmentsStore();
      const promiseList = [];

      if (
        this.proceedingContainer[proceedingID] !== undefined ||
        this.proceedingContainer[proceedingID].loading !== undefined
      ) {
        if (
          this.getParallelProceedingsByProceedingID(proceedingID).length ===
          this.proceedingContainer[proceedingID].workingCopy?.parallelverfahrenPlanIDs.length
        ) {
          return new Promise((resolve) => {
            resolve(this.getParallelProceedingsByProceedingID(proceedingID));
          });
        }

        appStore.showPageLoadingIndicator({
          id: "loadParallelProceeding",
          text: "Einen Moment bitte, die zugehörigen Verfahren werden geladen.",
        });

        this.proceedingContainer[proceedingID].workingCopy?.parallelverfahrenPlanIDs.forEach(
          /**
           * @param {string} pvID
           */
          (pvID) => {
            promiseList.push(
              new Promise((resolve, reject) => {
                this.proceedingContainer[proceedingID].loadingPV = true;

                openAPIFactory
                  .verfahrenResourceApiFactory()
                  .getVerfahrenDetail(pvID)
                  .then((response) => {
                    appointmentsStore
                      .loadProceedingAppointmentsByID({
                        proceedingID: pvID,
                        isPV: true,
                      })
                      .then(() => {
                        resolve(response.data);
                      })
                      .catch(() => {
                        resolve(response.data);
                      })
                      .finally(() => appStore.hidePageLoadingIndicator("loadParallelProceeding"));
                  })
                  .catch((error) => {
                    appStore.hidePageLoadingIndicator("loadParallelProceeding");

                    reject(error);
                  });
              }),
            );
          },
        );
      }

      return Promise.all(promiseList)
        .then((response) => {
          // here we DO NOT want to update the complete object as done in "cacheProceeding"
          // otherwise the GraphMilestoneGraphic Overview will be completely rerendered for each toggle of parallel proceedings badge
          if (this.proceedingContainer[proceedingID].loadingPV) {
            this.proceedingContainer[proceedingID].parallelverfahren = response;
            this.proceedingContainer[proceedingID].loadingPV = undefined;
          } else {
            this.proceedingContainer[proceedingID].parallelverfahren = [];
          }

          return this.getParallelProceedingsByProceedingID(proceedingID);
        })
        .catch((error) => {
          appStore.showErrorModal({
            response: error,
            customErrorMessage: "Laden der Verfahren fehlgeschlagen!",
          });

          return [];
        })
        .finally(() => appStore.hidePageLoadingIndicator("loadParallelProceeding"));
    },
    /**
     * Creates a new proceeding.
     * @param {Object} payload
     * @param {Object} payload.newProceeding
     *   The data object defining the new proceeding.
     * @returns {Promise}
     */
    createProceeding({ newProceeding }) {
      const appStore = useAppStore();
      const fundamentalStore = useFundamentalStore();

      appStore.showPageLoadingIndicator({
        id: "createProceeding",
        text: "Moment. Ihr Verfahren wird angelegt.",
      });

      let proceedingData = newProceeding;

      // set a fixed value for "verfahrenssteuerung" for wesia
      proceedingData["verfahrenssteuerung"] = {
        code: "0100",
      };

      // "zustaendigkeit" restricts the access of proceedings for certain roles (-> 'sachbearbeiter')
      // for WeSIA the "zustaendigkeit" is identical with the selected "bezirk"
      // therefor set "bezirk" implicity as value for "zustaendigkeit" if it exists in codelist
      if (fundamentalStore.checkValueInCodelist(proceedingData.bezirk[0], "zustaendigkeit")) {
        proceedingData["zustaendigkeit"] = {
          ...proceedingData.bezirk[0],
        };
      }

      return new Promise((resolve) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .createVerfahren(proceedingData)
          .then((response) => {
            const proceeding = response.data;

            this.cacheProceeding({
              proceeding: proceeding,
              isStub: false,
              subscribed: true,
            });

            this.subscribedProceedingIDs = [...this.subscribedProceedingIDs, proceeding.planID];

            appStore.resolved["/nutzer/verfahren"] = true;

            resolve(proceeding.planID);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Erstellen des Verfahrens fehlgeschlagen!",
            });
          })
          .finally(() => {
            appStore.hidePageLoadingIndicator("createProceeding");
          });
      });
    },
    /**
     * Updates a specific proceeding.
     * @param {Object} proceeding - The proceeding data that should get saved.
     * @returns {Promise} - Returns a promise that gets resolved or rejected based upon the response of the backend.
     */
    saveProceeding(proceeding) {
      const appStore = useAppStore();
      const fundamentalStore = useFundamentalStore();

      // remove deprecated "verfahrensschritt" key from data object
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let { verfahrensschritt, planID: proceedingID, ...proceedingData } = proceeding;

      // "bezirk" needs to be submitted as an array
      if (!Array.isArray(proceedingData.bezirk)) {
        proceedingData.bezirk = [proceedingData.bezirk];
      }

      // "zustaendigkeit" restricts the access of proceedings for certain roles (-> 'sachbearbeiter')
      // for WeSIA the "zustaendigkeit" is identical with the selected "bezirk"
      // therefor set "bezirk" implicity as value for "zustaendigkeit" if it exists in codelist
      if (fundamentalStore.checkValueInCodelist(proceedingData.bezirk[0], "zustaendigkeit")) {
        proceedingData["zustaendigkeit"] = {
          ...proceedingData.bezirk[0],
        };
      }

      // filter empty "jurymitglieder"
      proceedingData.jurymitglieder = proceedingData.jurymitglieder.filter((judge) => judge !== "");

      // add updated offices and filter empty "offices"
      proceedingData.teilnahmen = proceedingData.teilnahmen.reduce((acc, entry) => {
        entry.bueros = entry.bueros.filter((buero) => {
          return !!buero && buero.bueroname !== "";
        });
        if (entry.bueros.length > 0) {
          entry.id = Number(entry.id); // only number allowed
          acc.push(entry);
        }
        return acc;
      }, []);

      return new Promise((resolve, reject) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .updateVerfahren(proceedingData, proceedingID)
          .then((response) => {
            this.cacheProceeding({
              proceeding: response.data,
              isStub: false,
              subscribed: this.checkIfProceedingIsSubscribed(proceedingID),
              forceCache: true,
            });

            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Das Speichern der Änderungen ist fehlgeschlagen!",
            });

            reject(error);
          });
      });
    },
    /**
     * Loads the protocols of the proceeding.
     * @param {filterParams: Object, pageable: Object} payload
     * @param {{Status: String, Typ: String, Beschreibung: String[]}} payload.filterParams
     *    The filter params.
     * @param {{page: Number, size: Number, sort: String[]}} payload.pageable
     *    Pagination options.
     */
    loadProceedingProtocols({ filterParams, pageable }) {
      const proceedingID = getPlanID();

      return new Promise((resolve, reject) => {
        const status = filterParams.Status.length ? filterParams.Status : undefined;
        const typ = filterParams.Typ.length ? filterParams.Typ : undefined;
        const beschreibung = filterParams.Beschreibung.length ? filterParams.Beschreibung[0] : "";

        openAPIFactory
          .verfahrenResourceApiFactory()
          .getVerfahrenProtokolle(
            proceedingID,
            status,
            typ,
            beschreibung,
            pageable.page,
            pageable.size,
            pageable.sort,
          )
          .then((response) => {
            const protocolsContent = response.data;
            const protocolsData = [...this.protocolsData];
            const index = protocolsData.findIndex((protocol) => protocol.planID === proceedingID);

            if (index !== -1) {
              protocolsData[index] = { planID: proceedingID, protocolsContent };
            } else {
              protocolsData.push({
                planID: proceedingID,
                protocolsContent,
              });
            }

            this.protocolsData = protocolsData;

            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * Resets the working copy of a proceeding to its unaltered state.
     * @param {String} proceedingID
     *   The ID of the proceeding.
     * @returns {void}
     */
    resetProceedingWorkingCopy(proceedingID) {
      const proceedingData = { ...this.proceedingContainer };

      proceedingData[proceedingID].workingCopy = {
        ...this.proceedingContainer[proceedingID].backup,
      };

      this.proceedingContainer = proceedingData;
    },
    /**
     * Caches a proceeding object.
     * @param {Object} payload
     * @param {Object} payload.proceeding
     *   {proceeding: Object} The proceedings data object.
     * @param {Boolean} payload.isStub
     *   {isStub: Boolean} Flag determining if the proceeding object is a fully loaded one.
     * @param {Boolean} payload.subscribed
     *   {subscribed: Boolean} Flag determining if the current user is subscribed to this proceeding.
     * @param {Boolean} payload.forceCache
     *   {forceCache: Boolean} Force a caching (true for cache after save).
     * @returns {void}
     */
    cacheProceeding({ proceeding, isStub, subscribed, forceCache }) {
      const proceedingData = { ...this.proceedingContainer };

      if (
        proceedingData[proceeding.planID] === undefined ||
        proceedingData[proceeding.planID].loading ||
        proceedingData[proceeding.planID].isStub ||
        forceCache
      ) {
        proceedingData[proceeding.planID] = {
          workingCopy: proceeding,
          isStub,
          subscribed,
          backup: { ...proceeding },
          backupChecksum: objectHash.sha1(JSON.parse(JSON.stringify(proceeding))),
        };
      } else {
        proceedingData[proceeding.planID].subscribed = subscribed;
      }

      this.proceedingContainer = proceedingData;
    },
    /**
     * Set subscription flag of a specific proceeding and load proceeding if necessary
     * @param {Object} payload
     * @param {String} payload.proceedingID
     *   The proceeding ID.
     * @param {String[]} payload.subscribedProceedingIDs
     *   The resulting list of subscribed proceeding IDs.
     * @returns {void}
     */
    async addSubscription({ proceedingID, subscribedProceedingIDs }) {
      const appStore = useAppStore();

      this.subscribedProceedingIDs = subscribedProceedingIDs;

      if (this.proceedingContainer[proceedingID] === undefined) {
        await this.loadProceedingByID(proceedingID);
      } else {
        this.proceedingContainer[proceedingID].subscribed = true;
      }

      appStore.resolved["/nutzer/verfahren"] = true;
    },
    /**
     * Unset subscription flag of a specific proceeding
     * @param {Object} payload
     * @param {String} payload.proceedingID - The proceeding ID.
     * @param {String[]} payload.subscribedProceedingIDs - The resulting list of subscribed proceeding IDs.
     * @returns {void}
     */
    removeSubscription({ proceedingID, subscribedProceedingIDs }) {
      const appStore = useAppStore();

      if (this.proceedingContainer[proceedingID] !== undefined) {
        this.proceedingContainer[proceedingID].subscribed = false;
      }

      this.subscribedProceedingIDs = subscribedProceedingIDs;

      appStore.resolved["/nutzer/verfahren"] = true;
    },
    /**
     * Adds or removes an error from the errors set.
     * @param {{hasError: Boolean, id: String}} errorInfo - Object containing error info
     */
    handleErrors(errorInfo) {
      // if error exists add it to the set otherwise delete it from the set
      if (errorInfo.hasError) {
        this.errors.add(errorInfo.id);
      } else {
        this.errors.delete(errorInfo.id);
      }
    },
    /**
     * Resets all errors of the errors set.
     */
    resetErrors() {
      this.errors.clear();
    },
    /**
     * Returns the Preisgericht date of the selected proceeding.
     * @param planID
     */
    async getPreisgerichtDate(planID) {
      try {
        const response = await openAPIFactory
          .verfahrensteilschrittResourceApiFactory()
          .getVerfahrensteilschrittDetail(planID, "5020");

        for (let i = response.data.length - 1; i >= 0; i--) {
          // eslint-disable-next-line vue/max-len
          const actionItem =
            response.data[i]?.unterverfahrensteilschritte[0]?.aufgaben[0]?.unterAufgaben[0]
              ?.actionItems[0];

          if (actionItem.zeitraum && actionItem.zeitraum.ende) {
            return actionItem.zeitraum.ende;
          }
        }

        return null;
      } catch (error) {
        return null;
      }
    },
    getFormattedDate(date) {
      if (!date) return "";
      return moment(date).format("DD.MM.YYYY");
    },
    getYearFromDate(date) {
      if (!date) return "";
      return ` (${moment(date).year()})`;
    },
    getProceedingDetails({ bezirk, gebietseinheiten, wettbewerbsarten }) {
      const inputArray = [bezirk, gebietseinheiten, wettbewerbsarten];
      const outputArray = [];

      for (const input of inputArray) {
        if (input && input.length) {
          outputArray.push(input.map((el) => el.name).join(", "));
        }
      }

      return outputArray.join(" | ");
    },
  },
  getters: {
    /**
     * Returns an array of all subscribed proceedings, ordered by the user's preference.
     * @returns Array
     *   All subscribed proceedings in the desired order.
     */
    subscribedProceedings() {
      const fundamentalStore = useFundamentalStore();
      const sortingOrder = fundamentalStore.userSettings.cockpitSettings.sortingOrder;
      const ordered = [];
      let proceedings = [];

      Object.values(this.proceedingContainer).forEach((proceeding) => {
        if (proceeding.subscribed && !proceeding.loading) {
          proceedings.push(proceeding.workingCopy);
        }
      });

      // Make sure the store is write-protected from changes to this array
      proceedings = JSON.parse(JSON.stringify(proceedings));

      proceedings.sort((a, b) => {
        return a.planname.localeCompare(b.planname);
      });

      switch (sortingOrder) {
        case "CUSTOM": {
          const proceedingOrder =
            fundamentalStore.userSettings.cockpitSettings.customProceedingOrder;

          proceedingOrder.forEach((planID) => {
            const index = proceedings.findIndex((proceeding) => proceeding.planID === planID);

            if (index > -1) {
              ordered.push(proceedings.splice(index, 1).shift());
            }
          });

          return [...ordered, ...proceedings];
        }
        case "ARBEITSTITEL": {
          // sort option "Arbeitstitel" sorts by value "planname".
          // See README for more information

          return proceedings.sort((a, b) => {
            if (a.planname < b.planname) {
              return -1;
            }
            if (a.planname > b.planname) {
              return 1;
            }
            return 0;
          });
        }
        case "PREISGERICHT": {
          // sort option "PREISGERICHT" sorts by value "preisgerichtDatum".
          // first, proceedings without any "preisgerichtDatum"
          // then, proceedings chronologically sorted by "preisgerichtDatum"
          // See README for more information

          return proceedings.sort((a, b) => {
            const aHasDate = a.preisgerichtDatum !== null && a.preisgerichtDatum !== undefined;
            const bHasDate = b.preisgerichtDatum !== null && b.preisgerichtDatum !== undefined;

            if (!aHasDate && !bHasDate) return 0;
            if (!aHasDate) return -1;
            if (!bHasDate) return 1;

            if (a.preisgerichtDatum > b.preisgerichtDatum) {
              return -1;
            }
            if (a.preisgerichtDatum < b.preisgerichtDatum) {
              return 1;
            }
            return 0;
          });
        }
        default:
          return proceedings;
      }
    },
    /**
     * Checks if a proceeding is simulated.
     * @return {function(proceedingData: Object): Boolean}
     *   The result of the check.
     */
    checkIfProceedingIsSimulated() {
      return (proceedingData) => {
        let proceeding;

        if (proceedingData === undefined || proceedingData === null) {
          proceeding = this.getProceedingWorkingCopy();
        } else {
          switch (proceedingData.constructor.name) {
            case "String":
              proceeding = this.getProceedingWorkingCopy(proceedingData);
              break;
            default:
              proceeding = proceedingData;
          }
        }
        return proceeding.planstatus.code === "0300";
      };
    },
    /**
     * Checks if a proceeding is subscribed.
     * @returns {function(proceedingID: String): Boolean}
     *   The result of the check.
     */
    checkIfProceedingIsSubscribed() {
      return (proceedingID) => {
        proceedingID = proceedingID === undefined ? getPlanID() : proceedingID;

        return this.subscribedProceedingIDs.indexOf(proceedingID) > -1;
      };
    },
    /**
     * Returns a working copy of a specific proceeding.
     *
     * The function takes 2 arguments: {String} planID and {Boolean} writeable.
     * @returns {function(proceedingID: String, writeable: Boolean): Object}
     *   The working copy of the proceeding.
     */
    getProceedingWorkingCopy() {
      return (proceedingID, writeable = false) => {
        proceedingID = proceedingID === undefined ? getPlanID() : proceedingID;

        if (
          proceedingID &&
          this.proceedingContainer[proceedingID] !== undefined &&
          this.proceedingContainer[proceedingID].loading === undefined
        ) {
          return writeable
            ? this.proceedingContainer[proceedingID].workingCopy
            : { ...this.proceedingContainer[proceedingID].workingCopy };
        }

        return {};
      };
    },
    /**
     * Returns configuration of a specific proceeding.
     * @returns {object|undefined} proceeding configuration
     */
    getProceedingConfiguration() {
      return (proceedingID) => {
        const planID = typeof proceedingID === "string" ? proceedingID : getPlanID();

        if (planID !== undefined && typeof this.proceedingConfiguration[planID] !== "undefined") {
          return { ...this.proceedingConfiguration[planID] };
        }

        return undefined;
      };
    },
    getProceedingBackup() {
      return (proceedingID) => {
        proceedingID = proceedingID === undefined ? getPlanID() : proceedingID;

        if (
          proceedingID &&
          this.proceedingContainer[proceedingID] !== undefined &&
          this.proceedingContainer[proceedingID].loading === undefined
        ) {
          return { ...this.proceedingContainer[proceedingID].backup };
        }

        return {};
      };
    },
    /**
     * Returns the checksum of a proceeding working copy.
     * @return {function(proceedingID: String): String}
     *   The SHA1 checksum of a proceeding working copy.
     */
    getWorkingCopyChecksum() {
      return (proceedingID) => {
        proceedingID = proceedingID === undefined ? getPlanID() : proceedingID;

        return objectHash.sha1(
          JSON.parse(JSON.stringify(this.getProceedingWorkingCopy(proceedingID))),
        );
      };
    },
    /**
     * Checks if the state of a proceeding working copy has been altered.
     * @return {function(proceedingID: String): Boolean}
     *   The result of the check.
     */
    checkIfProceedingWorkingCopyHasBeenAltered() {
      return (proceedingID) => {
        proceedingID = proceedingID === undefined ? getPlanID() : proceedingID;

        if (
          !this.proceedingContainer[proceedingID] ||
          (this.proceedingContainer[proceedingID] !== undefined &&
            this.proceedingContainer[proceedingID].loading !== undefined)
        ) {
          return false;
        }

        return (
          this.getWorkingCopyChecksum(proceedingID) !==
          this.proceedingContainer[proceedingID].backupChecksum
        );
      };
    },
    /**
     * Returns a function retrieving the parallel proceedings of a proceeding defined by its ID.
     *
     * The function takes 1 argument: {String} proceedingID.
     * @returns {function(proceedingID: String): Array}
     *   An array of parallel proceedings.
     */
    getParallelProceedingsByProceedingID() {
      return (proceedingID) => {
        if (
          proceedingID &&
          this.proceedingContainer[proceedingID] !== undefined &&
          this.proceedingContainer[proceedingID].loading === undefined &&
          this.proceedingContainer[proceedingID].loadingPV === undefined &&
          this.proceedingContainer[proceedingID].parallelverfahren !== undefined
        ) {
          return this.proceedingContainer[proceedingID].parallelverfahren;
        }

        return [];
      };
    },
    /**
     * Checks if a proceeding is readonly because it is not subscribed
     * @return {function(proceedingID: String): Boolean}
     *   The result of the check.
     */
    checkIfProceedingIsReadonly() {
      return (proceedingID) => {
        proceedingID = proceedingID === undefined ? getPlanID() : proceedingID;

        const proceeding = this.getProceedingWorkingCopy(proceedingID);

        if (Object.keys(proceeding).length) {
          return !this.checkIfProceedingIsSubscribed(proceedingID);
        }

        return true;
      };
    },
    getProceedingStructureForProcessSteps() {
      return (planID) => {
        if (!planID) return undefined;

        const proceedingWorkingCopy = this.getProceedingWorkingCopy(planID);

        const proceedingConfiguration = this.getProceedingConfiguration(planID);

        if (
          proceedingWorkingCopy?.verfahrenssteuerung?.code &&
          proceedingWorkingCopy?.verfahrenssteuerung?.name
        ) {
          const vsObj = {};

          if (proceedingConfiguration?.verfahrensschritte) {
            for (const vs of proceedingConfiguration.verfahrensschritte) {
              const vtsObj = {};
              let stepIndex = 1;

              if (vs.verfahrensteilschritte) {
                for (const vts of vs.verfahrensteilschritte) {
                  if (
                    vts.codeVerfahrensteilschritt?.code &&
                    vts.codeVerfahrensteilschritt?.name &&
                    vs.codeVerfahrensschritt
                  ) {
                    vtsObj[vts.codeVerfahrensteilschritt.code] = {
                      name: vts.codeVerfahrensteilschritt.name,
                      codeVerfahrensschritt: vs.codeVerfahrensschritt,
                      stepIndex,
                      codeVerfahrensteilschritt: vts.codeVerfahrensteilschritt,
                      mindestdauer: vts.mindestdauer,
                      maxdauer: vts.maxdauer,
                      termine: vts.termine,
                      unterverfahrensteilschritte: vts.unterverfahrensteilschritte,
                      informationsID: vts.informationsID,
                    };

                    stepIndex++;
                  }
                }
              }

              if (vs.codeVerfahrensschritt?.code && vs.codeVerfahrensschritt?.name) {
                vsObj[vs.codeVerfahrensschritt.code] = {
                  name: vs.codeVerfahrensschritt.name,
                  verfahrensteilschritte: Object.keys(vtsObj).length > 0 ? vtsObj : undefined,
                };
              }
            }
          }

          return {
            name: proceedingWorkingCopy.verfahrenssteuerung.name,
            verfahrensschritte: Object.keys(vsObj).length > 0 ? vsObj : undefined,
          };
        }
      };
    },
    /**
     * Returns a function that returns proceeding structure of planID     */
    getProceedingStructures() {
      return (planID) => {
        const fundamentalStore = useFundamentalStore();

        const combinedProceedingStructures = {};

        const proceedingWorkingCopy = this.getProceedingWorkingCopy(planID);

        const proceedingConfiguration = this.getProceedingConfiguration(planID);

        // Make sure that proceeding codelist is actually sorted as this codelist determines
        // the order of the proceeding phases displayed.
        const proceedingPhaseOrder = fundamentalStore
          .getCodelistByName("verfahrensschritte")
          .sort((a, b) => {
            return a.code && b.code && a.code < b.code ? -1 : 1;
          });

        // count all added vts steps
        let stepCounter = 0;

        // Process each proceeding phase separately
        proceedingPhaseOrder.forEach((proceedingPhase, indexPhase) => {
          if (
            proceedingWorkingCopy?.verfahrenssteuerung?.code &&
            proceedingPhase.code &&
            proceedingPhase.name &&
            proceedingConfiguration?.verfahrensschritte &&
            proceedingConfiguration.verfahrensschritte.length > 0 &&
            // Check if the currently processed proceeding type features the current proceeding phase
            proceedingConfiguration.verfahrensschritte.find(
              (vts) => vts.codeVerfahrensschritt?.code === proceedingPhase.code,
            )
          ) {
            const vsStructure = proceedingConfiguration.verfahrensschritte.find(
              (vts) => vts.codeVerfahrensschritt?.code === proceedingPhase.code,
            );

            const vtsStructure = {};

            // check if the vs does contain at least one vts
            if (
              vsStructure?.verfahrensteilschritte &&
              vsStructure.verfahrensteilschritte.length > 0
            ) {
              vsStructure.verfahrensteilschritte.forEach((vts, indexVS) => {
                const vtsCode = vts?.codeVerfahrensteilschritt?.code;

                if (
                  vtsCode &&
                  vts.codeVerfahrensteilschritt?.name &&
                  vsStructure.codeVerfahrensschritt
                ) {
                  vtsStructure[vtsCode] = {
                    ...vts,
                    name: vts.codeVerfahrensteilschritt.name,
                    stepIndex: indexVS + 1,
                    codeVerfahrensschritt: vsStructure.codeVerfahrensschritt,
                  };
                }
              });

              // Note: combinedProceedingStructures does match structure of fundamental store state "proceedingStructures"
              // 'stepsBefore','maxSteps','phaseIndex' are added here to match the fundamental store "proceedingStructures"
              // this will be necessary if we reuse this a global method to be used in AppProcessSteps.vue etc
              combinedProceedingStructures[proceedingPhase.code] = {
                name: proceedingPhase.name,
                phaseIndex: indexPhase + 1,
                stepsBefore: stepCounter,
                maxSteps: vsStructure.verfahrensteilschritte.length,
                [proceedingWorkingCopy.verfahrenssteuerung.code]: vtsStructure,
              };

              stepCounter += vsStructure.verfahrensteilschritte.length;
            }
          }
        });

        return combinedProceedingStructures;
      };
    },
  },
});
