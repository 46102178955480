/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.144.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ErrorRest } from '../models';
// @ts-ignore
import type { GenericCodelistUebersichtRest } from '../models';
// @ts-ignore
import type { StammdatenRest } from '../models';
/**
 * GenericCodelistResourceApi - axios parameter creator
 * @export
 */
export const GenericCodelistResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle generischen Codelisten ausgeliefert.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
         * @summary Endpunkt der alle generischen Codelisten ausliefert.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGenericCodelists: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/genericcodelisten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine generische Codeliste als XOEV-Codeliste ausgeliefert.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt der den Inhalt einer Codeliste als Datei ausliefert.
         * @param {number} id ID der Codeliste
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenericCodelistDatei: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGenericCodelistDatei', 'id', id)
            const localVarPath = `/konfigurationen/genericcodelisten/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine generische Codelsite aktualisiert.<br> Die Zuordnung erfolgt dabei über die in der hochgeladenen XML enthaltene ListUri.<br> Wenn in der Codeliste Codes fehlen, die bereits in Verwendung sind, wird eine detaillierte Fehlermeldung zurückgegeben.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Aktualisieren einer generischen Codeliste.
         * @param {File} genericcodelist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGenericCodelistDatei: async (genericcodelist: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericcodelist' is not null or undefined
            assertParamExists('uploadGenericCodelistDatei', 'genericcodelist', genericcodelist)
            const localVarPath = `/konfigurationen/genericcodelisten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (genericcodelist !== undefined) { 
                localVarFormParams.append('genericcodelist', genericcodelist as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenericCodelistResourceApi - functional programming interface
 * @export
 */
export const GenericCodelistResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GenericCodelistResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle generischen Codelisten ausgeliefert.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
         * @summary Endpunkt der alle generischen Codelisten ausliefert.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllGenericCodelists(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericCodelistUebersichtRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGenericCodelists(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenericCodelistResourceApi.getAllGenericCodelists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine generische Codeliste als XOEV-Codeliste ausgeliefert.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt der den Inhalt einer Codeliste als Datei ausliefert.
         * @param {number} id ID der Codeliste
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGenericCodelistDatei(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGenericCodelistDatei(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenericCodelistResourceApi.getGenericCodelistDatei']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine generische Codelsite aktualisiert.<br> Die Zuordnung erfolgt dabei über die in der hochgeladenen XML enthaltene ListUri.<br> Wenn in der Codeliste Codes fehlen, die bereits in Verwendung sind, wird eine detaillierte Fehlermeldung zurückgegeben.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Aktualisieren einer generischen Codeliste.
         * @param {File} genericcodelist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadGenericCodelistDatei(genericcodelist: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StammdatenRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadGenericCodelistDatei(genericcodelist, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenericCodelistResourceApi.uploadGenericCodelistDatei']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GenericCodelistResourceApi - factory interface
 * @export
 */
export const GenericCodelistResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GenericCodelistResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle generischen Codelisten ausgeliefert.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
         * @summary Endpunkt der alle generischen Codelisten ausliefert.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGenericCodelists(options?: RawAxiosRequestConfig): AxiosPromise<Array<GenericCodelistUebersichtRest>> {
            return localVarFp.getAllGenericCodelists(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine generische Codeliste als XOEV-Codeliste ausgeliefert.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt der den Inhalt einer Codeliste als Datei ausliefert.
         * @param {number} id ID der Codeliste
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenericCodelistDatei(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getGenericCodelistDatei(id, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine generische Codelsite aktualisiert.<br> Die Zuordnung erfolgt dabei über die in der hochgeladenen XML enthaltene ListUri.<br> Wenn in der Codeliste Codes fehlen, die bereits in Verwendung sind, wird eine detaillierte Fehlermeldung zurückgegeben.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Aktualisieren einer generischen Codeliste.
         * @param {File} genericcodelist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGenericCodelistDatei(genericcodelist: File, options?: RawAxiosRequestConfig): AxiosPromise<StammdatenRest> {
            return localVarFp.uploadGenericCodelistDatei(genericcodelist, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GenericCodelistResourceApi - object-oriented interface
 * @export
 * @class GenericCodelistResourceApi
 * @extends {BaseAPI}
 */
export class GenericCodelistResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle generischen Codelisten ausgeliefert.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
     * @summary Endpunkt der alle generischen Codelisten ausliefert.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericCodelistResourceApi
     */
    public getAllGenericCodelists(options?: RawAxiosRequestConfig) {
        return GenericCodelistResourceApiFp(this.configuration).getAllGenericCodelists(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine generische Codeliste als XOEV-Codeliste ausgeliefert.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
     * @summary Endpunkt der den Inhalt einer Codeliste als Datei ausliefert.
     * @param {number} id ID der Codeliste
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericCodelistResourceApi
     */
    public getGenericCodelistDatei(id: number, options?: RawAxiosRequestConfig) {
        return GenericCodelistResourceApiFp(this.configuration).getGenericCodelistDatei(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine generische Codelsite aktualisiert.<br> Die Zuordnung erfolgt dabei über die in der hochgeladenen XML enthaltene ListUri.<br> Wenn in der Codeliste Codes fehlen, die bereits in Verwendung sind, wird eine detaillierte Fehlermeldung zurückgegeben.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
     * @summary Endpunkt zum Aktualisieren einer generischen Codeliste.
     * @param {File} genericcodelist 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericCodelistResourceApi
     */
    public uploadGenericCodelistDatei(genericcodelist: File, options?: RawAxiosRequestConfig) {
        return GenericCodelistResourceApiFp(this.configuration).uploadGenericCodelistDatei(genericcodelist, options).then((request) => request(this.axios, this.basePath));
    }
}

