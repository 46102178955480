/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.144.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BueroEditRest } from '../models';
// @ts-ignore
import type { BueroRest } from '../models';
// @ts-ignore
import type { CodeBuerotyp } from '../models';
// @ts-ignore
import type { CreateSimulatedTransferInMessageRequest } from '../models';
// @ts-ignore
import type { ErrorRest } from '../models';
// @ts-ignore
import type { PageBueroRest } from '../models';
/**
 * BueroResourceWesiaApi - axios parameter creator
 * @export
 */
export const BueroResourceWesiaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt kann mittels Suchparameter nach Büros gesucht werden.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
         * @summary Endpunkt für die Suche nach Büros.
         * @param {Array<CodeBuerotyp>} [bueroTyp] Typen des Büros
         * @param {string} [bueroname] Name des Büros
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buerosSuche: async (bueroTyp?: Array<CodeBuerotyp>, bueroname?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bueros/suche`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bueroTyp) {
                localVarQueryParameter['buero_typ'] = bueroTyp;
            }

            if (bueroname !== undefined) {
                localVarQueryParameter['bueroname'] = bueroname;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein neues Büro angelegt.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Anlegen eines Büros.
         * @param {BueroRest} bueroRest Metadaten des Büros
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewBuero: async (bueroRest: BueroRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bueroRest' is not null or undefined
            assertParamExists('createNewBuero', 'bueroRest', bueroRest)
            const localVarPath = `/bueros`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bueroRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein Büro gelöscht.</p><p><b>Benötigte Rechte:</b> BUERO_DELETE or ADMIN</p>
         * @summary Endpunkt zum Löschen eines Büros.
         * @param {string} bueroId bueroId vom Büro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuero: async (bueroId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bueroId' is not null or undefined
            assertParamExists('deleteBuero', 'bueroId', bueroId)
            const localVarPath = `/bueros/{bueroId}`
                .replace(`{${"bueroId"}}`, encodeURIComponent(String(bueroId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird der angegebene ehemalige Büroname aus der Liste ehemaliger Büronamen entfernt.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Entfernen eines ehemaligen Büronamens.
         * @param {string} bueroId bueroId vom Büro
         * @param {string} ehemaligerNameId ehemaliger Büroname der entfernt werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBueroname: async (bueroId: string, ehemaligerNameId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bueroId' is not null or undefined
            assertParamExists('deleteBueroname', 'bueroId', bueroId)
            // verify required parameter 'ehemaligerNameId' is not null or undefined
            assertParamExists('deleteBueroname', 'ehemaligerNameId', ehemaligerNameId)
            const localVarPath = `/bueros/{bueroId}/{ehemaligerNameId}`
                .replace(`{${"bueroId"}}`, encodeURIComponent(String(bueroId)))
                .replace(`{${"ehemaligerNameId"}}`, encodeURIComponent(String(ehemaligerNameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten eines Büros aktualisiert.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Aktualisieren eines Büros.
         * @param {BueroEditRest} bueroEditRest zu aktualisierende Metadaten vom Büro
         * @param {string} bueroId bueroId vom Büro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBuero: async (bueroEditRest: BueroEditRest, bueroId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bueroEditRest' is not null or undefined
            assertParamExists('editBuero', 'bueroEditRest', bueroEditRest)
            // verify required parameter 'bueroId' is not null or undefined
            assertParamExists('editBuero', 'bueroId', bueroId)
            const localVarPath = `/bueros/{bueroId}`
                .replace(`{${"bueroId"}}`, encodeURIComponent(String(bueroId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bueroEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle vorhandenen Büros ausgeliefert.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
         * @summary Endpunkt der alle Büros ausliefert.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBueros: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bueros`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden anhand einer ID die Metadaten eines Büros ausgeliefert.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
         * @summary Endpunkt der anhand einer ID die Metadaten eines Büros liefert.
         * @param {string} bueroId bueroId des Büros
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBueroById: async (bueroId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bueroId' is not null or undefined
            assertParamExists('getBueroById', 'bueroId', bueroId)
            const localVarPath = `/bueros/{bueroId}`
                .replace(`{${"bueroId"}}`, encodeURIComponent(String(bueroId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden Büros zwecks Teilnahme an Wettbewerben importiert.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Importieren von Büros.
         * @param {CreateSimulatedTransferInMessageRequest} [createSimulatedTransferInMessageRequest] Import-Datei mit Büros
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBuerosXml: async (createSimulatedTransferInMessageRequest?: CreateSimulatedTransferInMessageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bueros/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSimulatedTransferInMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BueroResourceWesiaApi - functional programming interface
 * @export
 */
export const BueroResourceWesiaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BueroResourceWesiaApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt kann mittels Suchparameter nach Büros gesucht werden.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
         * @summary Endpunkt für die Suche nach Büros.
         * @param {Array<CodeBuerotyp>} [bueroTyp] Typen des Büros
         * @param {string} [bueroname] Name des Büros
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buerosSuche(bueroTyp?: Array<CodeBuerotyp>, bueroname?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageBueroRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buerosSuche(bueroTyp, bueroname, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BueroResourceWesiaApi.buerosSuche']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein neues Büro angelegt.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Anlegen eines Büros.
         * @param {BueroRest} bueroRest Metadaten des Büros
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewBuero(bueroRest: BueroRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BueroRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewBuero(bueroRest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BueroResourceWesiaApi.createNewBuero']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein Büro gelöscht.</p><p><b>Benötigte Rechte:</b> BUERO_DELETE or ADMIN</p>
         * @summary Endpunkt zum Löschen eines Büros.
         * @param {string} bueroId bueroId vom Büro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBuero(bueroId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBuero(bueroId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BueroResourceWesiaApi.deleteBuero']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird der angegebene ehemalige Büroname aus der Liste ehemaliger Büronamen entfernt.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Entfernen eines ehemaligen Büronamens.
         * @param {string} bueroId bueroId vom Büro
         * @param {string} ehemaligerNameId ehemaliger Büroname der entfernt werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBueroname(bueroId: string, ehemaligerNameId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BueroRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBueroname(bueroId, ehemaligerNameId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BueroResourceWesiaApi.deleteBueroname']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten eines Büros aktualisiert.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Aktualisieren eines Büros.
         * @param {BueroEditRest} bueroEditRest zu aktualisierende Metadaten vom Büro
         * @param {string} bueroId bueroId vom Büro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBuero(bueroEditRest: BueroEditRest, bueroId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BueroRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editBuero(bueroEditRest, bueroId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BueroResourceWesiaApi.editBuero']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle vorhandenen Büros ausgeliefert.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
         * @summary Endpunkt der alle Büros ausliefert.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBueros(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageBueroRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBueros(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BueroResourceWesiaApi.getAllBueros']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden anhand einer ID die Metadaten eines Büros ausgeliefert.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
         * @summary Endpunkt der anhand einer ID die Metadaten eines Büros liefert.
         * @param {string} bueroId bueroId des Büros
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBueroById(bueroId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BueroRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBueroById(bueroId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BueroResourceWesiaApi.getBueroById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden Büros zwecks Teilnahme an Wettbewerben importiert.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Importieren von Büros.
         * @param {CreateSimulatedTransferInMessageRequest} [createSimulatedTransferInMessageRequest] Import-Datei mit Büros
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBuerosXml(createSimulatedTransferInMessageRequest?: CreateSimulatedTransferInMessageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BueroRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBuerosXml(createSimulatedTransferInMessageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BueroResourceWesiaApi.uploadBuerosXml']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BueroResourceWesiaApi - factory interface
 * @export
 */
export const BueroResourceWesiaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BueroResourceWesiaApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt kann mittels Suchparameter nach Büros gesucht werden.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
         * @summary Endpunkt für die Suche nach Büros.
         * @param {Array<CodeBuerotyp>} [bueroTyp] Typen des Büros
         * @param {string} [bueroname] Name des Büros
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buerosSuche(bueroTyp?: Array<CodeBuerotyp>, bueroname?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PageBueroRest> {
            return localVarFp.buerosSuche(bueroTyp, bueroname, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein neues Büro angelegt.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Anlegen eines Büros.
         * @param {BueroRest} bueroRest Metadaten des Büros
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewBuero(bueroRest: BueroRest, options?: RawAxiosRequestConfig): AxiosPromise<BueroRest> {
            return localVarFp.createNewBuero(bueroRest, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein Büro gelöscht.</p><p><b>Benötigte Rechte:</b> BUERO_DELETE or ADMIN</p>
         * @summary Endpunkt zum Löschen eines Büros.
         * @param {string} bueroId bueroId vom Büro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuero(bueroId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.deleteBuero(bueroId, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird der angegebene ehemalige Büroname aus der Liste ehemaliger Büronamen entfernt.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Entfernen eines ehemaligen Büronamens.
         * @param {string} bueroId bueroId vom Büro
         * @param {string} ehemaligerNameId ehemaliger Büroname der entfernt werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBueroname(bueroId: string, ehemaligerNameId: string, options?: RawAxiosRequestConfig): AxiosPromise<BueroRest> {
            return localVarFp.deleteBueroname(bueroId, ehemaligerNameId, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten eines Büros aktualisiert.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Aktualisieren eines Büros.
         * @param {BueroEditRest} bueroEditRest zu aktualisierende Metadaten vom Büro
         * @param {string} bueroId bueroId vom Büro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBuero(bueroEditRest: BueroEditRest, bueroId: string, options?: RawAxiosRequestConfig): AxiosPromise<BueroRest> {
            return localVarFp.editBuero(bueroEditRest, bueroId, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle vorhandenen Büros ausgeliefert.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
         * @summary Endpunkt der alle Büros ausliefert.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBueros(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PageBueroRest> {
            return localVarFp.getAllBueros(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden anhand einer ID die Metadaten eines Büros ausgeliefert.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
         * @summary Endpunkt der anhand einer ID die Metadaten eines Büros liefert.
         * @param {string} bueroId bueroId des Büros
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBueroById(bueroId: string, options?: RawAxiosRequestConfig): AxiosPromise<BueroRest> {
            return localVarFp.getBueroById(bueroId, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden Büros zwecks Teilnahme an Wettbewerben importiert.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
         * @summary Endpunkt zum Importieren von Büros.
         * @param {CreateSimulatedTransferInMessageRequest} [createSimulatedTransferInMessageRequest] Import-Datei mit Büros
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBuerosXml(createSimulatedTransferInMessageRequest?: CreateSimulatedTransferInMessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<BueroRest>> {
            return localVarFp.uploadBuerosXml(createSimulatedTransferInMessageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BueroResourceWesiaApi - object-oriented interface
 * @export
 * @class BueroResourceWesiaApi
 * @extends {BaseAPI}
 */
export class BueroResourceWesiaApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt kann mittels Suchparameter nach Büros gesucht werden.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
     * @summary Endpunkt für die Suche nach Büros.
     * @param {Array<CodeBuerotyp>} [bueroTyp] Typen des Büros
     * @param {string} [bueroname] Name des Büros
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BueroResourceWesiaApi
     */
    public buerosSuche(bueroTyp?: Array<CodeBuerotyp>, bueroname?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return BueroResourceWesiaApiFp(this.configuration).buerosSuche(bueroTyp, bueroname, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein neues Büro angelegt.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
     * @summary Endpunkt zum Anlegen eines Büros.
     * @param {BueroRest} bueroRest Metadaten des Büros
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BueroResourceWesiaApi
     */
    public createNewBuero(bueroRest: BueroRest, options?: RawAxiosRequestConfig) {
        return BueroResourceWesiaApiFp(this.configuration).createNewBuero(bueroRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein Büro gelöscht.</p><p><b>Benötigte Rechte:</b> BUERO_DELETE or ADMIN</p>
     * @summary Endpunkt zum Löschen eines Büros.
     * @param {string} bueroId bueroId vom Büro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BueroResourceWesiaApi
     */
    public deleteBuero(bueroId: string, options?: RawAxiosRequestConfig) {
        return BueroResourceWesiaApiFp(this.configuration).deleteBuero(bueroId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird der angegebene ehemalige Büroname aus der Liste ehemaliger Büronamen entfernt.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
     * @summary Endpunkt zum Entfernen eines ehemaligen Büronamens.
     * @param {string} bueroId bueroId vom Büro
     * @param {string} ehemaligerNameId ehemaliger Büroname der entfernt werden soll.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BueroResourceWesiaApi
     */
    public deleteBueroname(bueroId: string, ehemaligerNameId: string, options?: RawAxiosRequestConfig) {
        return BueroResourceWesiaApiFp(this.configuration).deleteBueroname(bueroId, ehemaligerNameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten eines Büros aktualisiert.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
     * @summary Endpunkt zum Aktualisieren eines Büros.
     * @param {BueroEditRest} bueroEditRest zu aktualisierende Metadaten vom Büro
     * @param {string} bueroId bueroId vom Büro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BueroResourceWesiaApi
     */
    public editBuero(bueroEditRest: BueroEditRest, bueroId: string, options?: RawAxiosRequestConfig) {
        return BueroResourceWesiaApiFp(this.configuration).editBuero(bueroEditRest, bueroId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle vorhandenen Büros ausgeliefert.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
     * @summary Endpunkt der alle Büros ausliefert.
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BueroResourceWesiaApi
     */
    public getAllBueros(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return BueroResourceWesiaApiFp(this.configuration).getAllBueros(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden anhand einer ID die Metadaten eines Büros ausgeliefert.</p><p><b>Benötigte Rechte:</b> BUERO_READ or ADMIN</p>
     * @summary Endpunkt der anhand einer ID die Metadaten eines Büros liefert.
     * @param {string} bueroId bueroId des Büros
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BueroResourceWesiaApi
     */
    public getBueroById(bueroId: string, options?: RawAxiosRequestConfig) {
        return BueroResourceWesiaApiFp(this.configuration).getBueroById(bueroId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden Büros zwecks Teilnahme an Wettbewerben importiert.</p><p><b>Benötigte Rechte:</b> BUERO_WRITE or ADMIN</p>
     * @summary Endpunkt zum Importieren von Büros.
     * @param {CreateSimulatedTransferInMessageRequest} [createSimulatedTransferInMessageRequest] Import-Datei mit Büros
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BueroResourceWesiaApi
     */
    public uploadBuerosXml(createSimulatedTransferInMessageRequest?: CreateSimulatedTransferInMessageRequest, options?: RawAxiosRequestConfig) {
        return BueroResourceWesiaApiFp(this.configuration).uploadBuerosXml(createSimulatedTransferInMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

