/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.144.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CodeVerfahrenssteuerung } from '../models';
// @ts-ignore
import type { ErrorRest } from '../models';
// @ts-ignore
import type { PageVerfahrensKonfigurationUebersichtRest } from '../models';
// @ts-ignore
import type { StammdatenLogRest } from '../models';
// @ts-ignore
import type { StammdatenRest } from '../models';
// @ts-ignore
import type { VerfahrensKonfigurationRest } from '../models';
/**
 * VerfahrensKonfigurationRessourceApi - axios parameter creator
 * @export
 */
export const VerfahrensKonfigurationRessourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt können spezifische Verfahrenskonfigurationen gelöscht werden.<br> Das Löschen der Verfahrenskonfiguration hat keinen Einfluss auf Verfahren, welche bereits mit dieser<br> Verfahrenskonfiguration angelegt wurden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Löschen von einer Verfahrenskonfiguration.
         * @param {string} codeVerfahrenssteuerung Verfahrenssteuerung
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVerfahrenskonfigurationen: async (codeVerfahrenssteuerung: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codeVerfahrenssteuerung' is not null or undefined
            assertParamExists('deleteVerfahrenskonfigurationen', 'codeVerfahrenssteuerung', codeVerfahrenssteuerung)
            const localVarPath = `/konfigurationen/verfahrenskonfigurationen/{codeVerfahrenssteuerung}`
                .replace(`{${"codeVerfahrenssteuerung"}}`, encodeURIComponent(String(codeVerfahrenssteuerung)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle Verfahrenskonfigurationen als XML-Datei in einem ZIP-Archiv heruntergeladen.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Herunterladen aller Verfahrenskonfigurationen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVerfahrenskonfigurationen: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/verfahrenskonfiguration/datei`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Es werden nur VerfahrenKonfigurationen geliefert, für welche der aktuelle Nutzer berechtigt ist.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
         * @summary Endpunkt zum Auflisten aller Verfahrenskonfigurationen unter Berücksichtigung der Zuständigkeit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/verfahren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Verfahrenskonfiguration in Abhängikeit der Verfahrenssteuerung aufgelistet.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
         * @summary Endpunkt zur Auflistung der VerfahrenKonfiguration für die übergebene Verfahrenssteuerung.
         * @param {string} codeVerfahrenssteuerung Verfahrenssteuerung
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByVerfahrenssteuerung: async (codeVerfahrenssteuerung: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codeVerfahrenssteuerung' is not null or undefined
            assertParamExists('getByVerfahrenssteuerung', 'codeVerfahrenssteuerung', codeVerfahrenssteuerung)
            const localVarPath = `/konfigurationen/verfahren/{codeVerfahrenssteuerung}`
                .replace(`{${"codeVerfahrenssteuerung"}}`, encodeURIComponent(String(codeVerfahrenssteuerung)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird die komplette Codeliste als XML-Datei ausgeliefert.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zur Auslieferung der vorhandenen Codelisten als XML Datei.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodelistenKonfiguration: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/codelistenkonfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Liefert das Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Auflisten des Protokolls von hochgeladenen Dateien aus dem StammdatenLogService.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStammdatenLogs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/verfahren/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Es werden Infos wie letzter Bearbeiter oder die Anzahl der Verfahren für die Verfahrenskonfiguration geliefert,<br> der eigentliche Inhalt der Verfahrenskonfiguration wird hier nicht mit geliefert.<br> Die Ergebnismenge kann durch optionale Suchparameter eingegrenzt werden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Der Endpunkt liefert eine Übersicht über die im System vorhandenen Verfahrenskonfigurationen.
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] Verfahrenssteuerungen
         * @param {string} [bearbeiterDatumStart] Bearbeiter Datum Start
         * @param {string} [bearbeiterDatumEnd] Bearbeiter Datum Ende
         * @param {boolean} [verfahrenInVerwendung] Verfahren in Verwendung
         * @param {string} [bearbeiter] Bearbeiter
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrenskonfigurationenUebersicht: async (codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, bearbeiterDatumStart?: string, bearbeiterDatumEnd?: string, verfahrenInVerwendung?: boolean, bearbeiter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/verfahrenskonfigurationen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codeVerfahrenssteuerung) {
                localVarQueryParameter['codeVerfahrenssteuerung'] = codeVerfahrenssteuerung;
            }

            if (bearbeiterDatumStart !== undefined) {
                localVarQueryParameter['bearbeiterDatumStart'] = (bearbeiterDatumStart as any instanceof Date) ?
                    (bearbeiterDatumStart as any).toISOString() :
                    bearbeiterDatumStart;
            }

            if (bearbeiterDatumEnd !== undefined) {
                localVarQueryParameter['bearbeiterDatumEnd'] = (bearbeiterDatumEnd as any instanceof Date) ?
                    (bearbeiterDatumEnd as any).toISOString() :
                    bearbeiterDatumEnd;
            }

            if (verfahrenInVerwendung !== undefined) {
                localVarQueryParameter['verfahrenInVerwendung'] = verfahrenInVerwendung;
            }

            if (bearbeiter !== undefined) {
                localVarQueryParameter['bearbeiter'] = bearbeiter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Übernimmt die im Anhang übergebenen Verfahrenskonfigurationen in das System.<br><br> Es muss eine XML Datei mit dem Root Element verfahrenskonfigurationen übermittelt werden. Deren Struktur ist<br> durch die diplanung.xsd definiert. Weitere Informationen dazu finden sie im<br> <a href=\"https://wiki.diplanung.de/index.php?title=Verfahrenskonfiguration_Pro\">DiPlanung Wiki</a></p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Hochladen von Verfahrenskonfigurationen.
         * @param {File} verfahrenskonfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStammdatenVerfahrenskonfiguration: async (verfahrenskonfiguration: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenskonfiguration' is not null or undefined
            assertParamExists('uploadStammdatenVerfahrenskonfiguration', 'verfahrenskonfiguration', verfahrenskonfiguration)
            const localVarPath = `/konfigurationen/verfahrenskonfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (verfahrenskonfiguration !== undefined) { 
                localVarFormParams.append('verfahrenskonfiguration', verfahrenskonfiguration as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt können Verfahren in das System hochgeladen werden.<br> Format der XML-Datei ist im Standard XBauleitplanung definiert.<br> Die xml ist in der xbauleitplanung-planung2cockpit.xsd definiert.<br> Das Root Element ist Planung2CockpitUebersichtVerfahrenNeuOK0811.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Hochladen von Verfahren.
         * @param {File} [verfahrensuebersicht] 
         * @param {boolean} [overwriteVerfahren] Verfahren überschreiben
         * @param {boolean} [deleteAllVerfahren] Löschen aller Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStammdatenVerfahrensuebersicht: async (verfahrensuebersicht?: File, overwriteVerfahren?: boolean, deleteAllVerfahren?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/verfahren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (overwriteVerfahren !== undefined) {
                localVarQueryParameter['overwriteVerfahren'] = overwriteVerfahren;
            }

            if (deleteAllVerfahren !== undefined) {
                localVarQueryParameter['deleteAllVerfahren'] = deleteAllVerfahren;
            }


            if (verfahrensuebersicht !== undefined) { 
                localVarFormParams.append('verfahrensuebersicht', verfahrensuebersicht as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerfahrensKonfigurationRessourceApi - functional programming interface
 * @export
 */
export const VerfahrensKonfigurationRessourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerfahrensKonfigurationRessourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt können spezifische Verfahrenskonfigurationen gelöscht werden.<br> Das Löschen der Verfahrenskonfiguration hat keinen Einfluss auf Verfahren, welche bereits mit dieser<br> Verfahrenskonfiguration angelegt wurden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Löschen von einer Verfahrenskonfiguration.
         * @param {string} codeVerfahrenssteuerung Verfahrenssteuerung
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVerfahrenskonfigurationen(codeVerfahrenssteuerung: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVerfahrenskonfigurationen(codeVerfahrenssteuerung, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.deleteVerfahrenskonfigurationen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle Verfahrenskonfigurationen als XML-Datei in einem ZIP-Archiv heruntergeladen.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Herunterladen aller Verfahrenskonfigurationen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadVerfahrenskonfigurationen(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadVerfahrenskonfigurationen(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.downloadVerfahrenskonfigurationen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Es werden nur VerfahrenKonfigurationen geliefert, für welche der aktuelle Nutzer berechtigt ist.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
         * @summary Endpunkt zum Auflisten aller Verfahrenskonfigurationen unter Berücksichtigung der Zuständigkeit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrensKonfigurationRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.getAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Verfahrenskonfiguration in Abhängikeit der Verfahrenssteuerung aufgelistet.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
         * @summary Endpunkt zur Auflistung der VerfahrenKonfiguration für die übergebene Verfahrenssteuerung.
         * @param {string} codeVerfahrenssteuerung Verfahrenssteuerung
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByVerfahrenssteuerung(codeVerfahrenssteuerung: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrensKonfigurationRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByVerfahrenssteuerung(codeVerfahrenssteuerung, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.getByVerfahrenssteuerung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird die komplette Codeliste als XML-Datei ausgeliefert.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zur Auslieferung der vorhandenen Codelisten als XML Datei.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCodelistenKonfiguration(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCodelistenKonfiguration(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.getCodelistenKonfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Liefert das Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Auflisten des Protokolls von hochgeladenen Dateien aus dem StammdatenLogService.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStammdatenLogs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StammdatenLogRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStammdatenLogs(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.getStammdatenLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Es werden Infos wie letzter Bearbeiter oder die Anzahl der Verfahren für die Verfahrenskonfiguration geliefert,<br> der eigentliche Inhalt der Verfahrenskonfiguration wird hier nicht mit geliefert.<br> Die Ergebnismenge kann durch optionale Suchparameter eingegrenzt werden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Der Endpunkt liefert eine Übersicht über die im System vorhandenen Verfahrenskonfigurationen.
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] Verfahrenssteuerungen
         * @param {string} [bearbeiterDatumStart] Bearbeiter Datum Start
         * @param {string} [bearbeiterDatumEnd] Bearbeiter Datum Ende
         * @param {boolean} [verfahrenInVerwendung] Verfahren in Verwendung
         * @param {string} [bearbeiter] Bearbeiter
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerfahrenskonfigurationenUebersicht(codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, bearbeiterDatumStart?: string, bearbeiterDatumEnd?: string, verfahrenInVerwendung?: boolean, bearbeiter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageVerfahrensKonfigurationUebersichtRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerfahrenskonfigurationenUebersicht(codeVerfahrenssteuerung, bearbeiterDatumStart, bearbeiterDatumEnd, verfahrenInVerwendung, bearbeiter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.getVerfahrenskonfigurationenUebersicht']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Übernimmt die im Anhang übergebenen Verfahrenskonfigurationen in das System.<br><br> Es muss eine XML Datei mit dem Root Element verfahrenskonfigurationen übermittelt werden. Deren Struktur ist<br> durch die diplanung.xsd definiert. Weitere Informationen dazu finden sie im<br> <a href=\"https://wiki.diplanung.de/index.php?title=Verfahrenskonfiguration_Pro\">DiPlanung Wiki</a></p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Hochladen von Verfahrenskonfigurationen.
         * @param {File} verfahrenskonfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StammdatenRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.uploadStammdatenVerfahrenskonfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt können Verfahren in das System hochgeladen werden.<br> Format der XML-Datei ist im Standard XBauleitplanung definiert.<br> Die xml ist in der xbauleitplanung-planung2cockpit.xsd definiert.<br> Das Root Element ist Planung2CockpitUebersichtVerfahrenNeuOK0811.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Hochladen von Verfahren.
         * @param {File} [verfahrensuebersicht] 
         * @param {boolean} [overwriteVerfahren] Verfahren überschreiben
         * @param {boolean} [deleteAllVerfahren] Löschen aller Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht?: File, overwriteVerfahren?: boolean, deleteAllVerfahren?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StammdatenRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht, overwriteVerfahren, deleteAllVerfahren, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensKonfigurationRessourceApi.uploadStammdatenVerfahrensuebersicht']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VerfahrensKonfigurationRessourceApi - factory interface
 * @export
 */
export const VerfahrensKonfigurationRessourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerfahrensKonfigurationRessourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt können spezifische Verfahrenskonfigurationen gelöscht werden.<br> Das Löschen der Verfahrenskonfiguration hat keinen Einfluss auf Verfahren, welche bereits mit dieser<br> Verfahrenskonfiguration angelegt wurden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Löschen von einer Verfahrenskonfiguration.
         * @param {string} codeVerfahrenssteuerung Verfahrenssteuerung
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVerfahrenskonfigurationen(codeVerfahrenssteuerung: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteVerfahrenskonfigurationen(codeVerfahrenssteuerung, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle Verfahrenskonfigurationen als XML-Datei in einem ZIP-Archiv heruntergeladen.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Herunterladen aller Verfahrenskonfigurationen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVerfahrenskonfigurationen(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.downloadVerfahrenskonfigurationen(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Es werden nur VerfahrenKonfigurationen geliefert, für welche der aktuelle Nutzer berechtigt ist.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
         * @summary Endpunkt zum Auflisten aller Verfahrenskonfigurationen unter Berücksichtigung der Zuständigkeit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrensKonfigurationRest>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Verfahrenskonfiguration in Abhängikeit der Verfahrenssteuerung aufgelistet.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
         * @summary Endpunkt zur Auflistung der VerfahrenKonfiguration für die übergebene Verfahrenssteuerung.
         * @param {string} codeVerfahrenssteuerung Verfahrenssteuerung
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByVerfahrenssteuerung(codeVerfahrenssteuerung: string, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrensKonfigurationRest> {
            return localVarFp.getByVerfahrenssteuerung(codeVerfahrenssteuerung, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird die komplette Codeliste als XML-Datei ausgeliefert.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zur Auslieferung der vorhandenen Codelisten als XML Datei.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodelistenKonfiguration(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getCodelistenKonfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Liefert das Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Auflisten des Protokolls von hochgeladenen Dateien aus dem StammdatenLogService.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStammdatenLogs(options?: RawAxiosRequestConfig): AxiosPromise<Array<StammdatenLogRest>> {
            return localVarFp.getStammdatenLogs(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Es werden Infos wie letzter Bearbeiter oder die Anzahl der Verfahren für die Verfahrenskonfiguration geliefert,<br> der eigentliche Inhalt der Verfahrenskonfiguration wird hier nicht mit geliefert.<br> Die Ergebnismenge kann durch optionale Suchparameter eingegrenzt werden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Der Endpunkt liefert eine Übersicht über die im System vorhandenen Verfahrenskonfigurationen.
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] Verfahrenssteuerungen
         * @param {string} [bearbeiterDatumStart] Bearbeiter Datum Start
         * @param {string} [bearbeiterDatumEnd] Bearbeiter Datum Ende
         * @param {boolean} [verfahrenInVerwendung] Verfahren in Verwendung
         * @param {string} [bearbeiter] Bearbeiter
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrenskonfigurationenUebersicht(codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, bearbeiterDatumStart?: string, bearbeiterDatumEnd?: string, verfahrenInVerwendung?: boolean, bearbeiter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PageVerfahrensKonfigurationUebersichtRest> {
            return localVarFp.getVerfahrenskonfigurationenUebersicht(codeVerfahrenssteuerung, bearbeiterDatumStart, bearbeiterDatumEnd, verfahrenInVerwendung, bearbeiter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Übernimmt die im Anhang übergebenen Verfahrenskonfigurationen in das System.<br><br> Es muss eine XML Datei mit dem Root Element verfahrenskonfigurationen übermittelt werden. Deren Struktur ist<br> durch die diplanung.xsd definiert. Weitere Informationen dazu finden sie im<br> <a href=\"https://wiki.diplanung.de/index.php?title=Verfahrenskonfiguration_Pro\">DiPlanung Wiki</a></p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Hochladen von Verfahrenskonfigurationen.
         * @param {File} verfahrenskonfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration: File, options?: RawAxiosRequestConfig): AxiosPromise<StammdatenRest> {
            return localVarFp.uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt können Verfahren in das System hochgeladen werden.<br> Format der XML-Datei ist im Standard XBauleitplanung definiert.<br> Die xml ist in der xbauleitplanung-planung2cockpit.xsd definiert.<br> Das Root Element ist Planung2CockpitUebersichtVerfahrenNeuOK0811.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Hochladen von Verfahren.
         * @param {File} [verfahrensuebersicht] 
         * @param {boolean} [overwriteVerfahren] Verfahren überschreiben
         * @param {boolean} [deleteAllVerfahren] Löschen aller Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht?: File, overwriteVerfahren?: boolean, deleteAllVerfahren?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<StammdatenRest> {
            return localVarFp.uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht, overwriteVerfahren, deleteAllVerfahren, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerfahrensKonfigurationRessourceApi - object-oriented interface
 * @export
 * @class VerfahrensKonfigurationRessourceApi
 * @extends {BaseAPI}
 */
export class VerfahrensKonfigurationRessourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt können spezifische Verfahrenskonfigurationen gelöscht werden.<br> Das Löschen der Verfahrenskonfiguration hat keinen Einfluss auf Verfahren, welche bereits mit dieser<br> Verfahrenskonfiguration angelegt wurden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
     * @summary Endpunkt zum Löschen von einer Verfahrenskonfiguration.
     * @param {string} codeVerfahrenssteuerung Verfahrenssteuerung
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public deleteVerfahrenskonfigurationen(codeVerfahrenssteuerung: string, options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).deleteVerfahrenskonfigurationen(codeVerfahrenssteuerung, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle Verfahrenskonfigurationen als XML-Datei in einem ZIP-Archiv heruntergeladen.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
     * @summary Endpunkt zum Herunterladen aller Verfahrenskonfigurationen.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public downloadVerfahrenskonfigurationen(options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).downloadVerfahrenskonfigurationen(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Es werden nur VerfahrenKonfigurationen geliefert, für welche der aktuelle Nutzer berechtigt ist.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
     * @summary Endpunkt zum Auflisten aller Verfahrenskonfigurationen unter Berücksichtigung der Zuständigkeit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public getAll(options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Verfahrenskonfiguration in Abhängikeit der Verfahrenssteuerung aufgelistet.</p><p><b>Benötigte Rechte:</b> isAuthenticated()</p>
     * @summary Endpunkt zur Auflistung der VerfahrenKonfiguration für die übergebene Verfahrenssteuerung.
     * @param {string} codeVerfahrenssteuerung Verfahrenssteuerung
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public getByVerfahrenssteuerung(codeVerfahrenssteuerung: string, options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).getByVerfahrenssteuerung(codeVerfahrenssteuerung, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird die komplette Codeliste als XML-Datei ausgeliefert.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
     * @summary Endpunkt zur Auslieferung der vorhandenen Codelisten als XML Datei.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public getCodelistenKonfiguration(options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).getCodelistenKonfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Liefert das Protokoll von hochgeladenen Dateien aus dem StammdatenLogService.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
     * @summary Endpunkt zum Auflisten des Protokolls von hochgeladenen Dateien aus dem StammdatenLogService.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public getStammdatenLogs(options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).getStammdatenLogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Es werden Infos wie letzter Bearbeiter oder die Anzahl der Verfahren für die Verfahrenskonfiguration geliefert,<br> der eigentliche Inhalt der Verfahrenskonfiguration wird hier nicht mit geliefert.<br> Die Ergebnismenge kann durch optionale Suchparameter eingegrenzt werden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
     * @summary Der Endpunkt liefert eine Übersicht über die im System vorhandenen Verfahrenskonfigurationen.
     * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] Verfahrenssteuerungen
     * @param {string} [bearbeiterDatumStart] Bearbeiter Datum Start
     * @param {string} [bearbeiterDatumEnd] Bearbeiter Datum Ende
     * @param {boolean} [verfahrenInVerwendung] Verfahren in Verwendung
     * @param {string} [bearbeiter] Bearbeiter
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public getVerfahrenskonfigurationenUebersicht(codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, bearbeiterDatumStart?: string, bearbeiterDatumEnd?: string, verfahrenInVerwendung?: boolean, bearbeiter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).getVerfahrenskonfigurationenUebersicht(codeVerfahrenssteuerung, bearbeiterDatumStart, bearbeiterDatumEnd, verfahrenInVerwendung, bearbeiter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Übernimmt die im Anhang übergebenen Verfahrenskonfigurationen in das System.<br><br> Es muss eine XML Datei mit dem Root Element verfahrenskonfigurationen übermittelt werden. Deren Struktur ist<br> durch die diplanung.xsd definiert. Weitere Informationen dazu finden sie im<br> <a href=\"https://wiki.diplanung.de/index.php?title=Verfahrenskonfiguration_Pro\">DiPlanung Wiki</a></p><p><b>Benötigte Rechte:</b> ADMIN</p>
     * @summary Endpunkt zum Hochladen von Verfahrenskonfigurationen.
     * @param {File} verfahrenskonfiguration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration: File, options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).uploadStammdatenVerfahrenskonfiguration(verfahrenskonfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt können Verfahren in das System hochgeladen werden.<br> Format der XML-Datei ist im Standard XBauleitplanung definiert.<br> Die xml ist in der xbauleitplanung-planung2cockpit.xsd definiert.<br> Das Root Element ist Planung2CockpitUebersichtVerfahrenNeuOK0811.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
     * @summary Endpunkt zum Hochladen von Verfahren.
     * @param {File} [verfahrensuebersicht] 
     * @param {boolean} [overwriteVerfahren] Verfahren überschreiben
     * @param {boolean} [deleteAllVerfahren] Löschen aller Verfahren
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensKonfigurationRessourceApi
     */
    public uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht?: File, overwriteVerfahren?: boolean, deleteAllVerfahren?: boolean, options?: RawAxiosRequestConfig) {
        return VerfahrensKonfigurationRessourceApiFp(this.configuration).uploadStammdatenVerfahrensuebersicht(verfahrensuebersicht, overwriteVerfahren, deleteAllVerfahren, options).then((request) => request(this.axios, this.basePath));
    }
}

