/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.144.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ErrorRest } from '../models';
// @ts-ignore
import type { VerfahrensteilschrittDetailRest } from '../models';
// @ts-ignore
import type { VerfahrensteilschrittUebersichtRest } from '../models';
/**
 * VerfahrensteilschrittResourceApi - axios parameter creator
 * @export
 */
export const VerfahrensteilschrittResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein neuer Durchgang für einen Verfahrensschritt angelegt.<br> Basis ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Anlegen eines neuen Durchgangs für einen Verfahrensschritt.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensschritt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDurchgangVs: async (planID: string, code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('createDurchgangVs', 'planID', planID)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('createDurchgangVs', 'code', code)
            const localVarPath = `/verfahren/{planID}/verfahrensschritte/{code}/durchgaenge`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt kann ein neuer Durchgang eines Verfahrensteilschritts angelegt werden.<br> Basis ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Anlegen eines neuen Durchgangs eines Verfahrensteilschritts.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensteilschritt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDurchgangVts: async (planID: string, code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('createDurchgangVts', 'planID', planID)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('createDurchgangVts', 'code', code)
            const localVarPath = `/verfahren/{planID}/verfahrensteilschritte/{code}/durchgaenge`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein Durchgang eines Verfahrensteilschritts gelöscht.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Löschen eines Durchgangs aus einem Verfahrensteilschritt.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensteilschritt
         * @param {number} durchgangsnummer Durchgangsnummer (beginnt mit 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDurchgangVts: async (planID: string, code: string, durchgangsnummer: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('deleteDurchgangVts', 'planID', planID)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('deleteDurchgangVts', 'code', code)
            // verify required parameter 'durchgangsnummer' is not null or undefined
            assertParamExists('deleteDurchgangVts', 'durchgangsnummer', durchgangsnummer)
            const localVarPath = `/verfahren/{planID}/verfahrensteilschritte/{code}/durchgaenge/{durchgangsnummer}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)))
                .replace(`{${"durchgangsnummer"}}`, encodeURIComponent(String(durchgangsnummer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten aller Durchgänge eines Verfahrensteilschrittes ausgeliefert.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
         * @summary Endpunkt der die Metadaten aller Durchgänge eines Verfahrensteilschritts liefert.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensteilschritt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrensteilschrittDetail: async (planID: string, code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVerfahrensteilschrittDetail', 'planID', planID)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getVerfahrensteilschrittDetail', 'code', code)
            const localVarPath = `/verfahren/{planID}/verfahrensteilschritte/{code}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten des Verfahrensablaufs eines Verfahrens geliefert.<br> Liefert die Detailinformationen zu allen Verfahrensteilschritten eines Verfahrens.<br> Die zurückgegebenen Daten beinhalten auch die Unterverfahrensteilschritte und Termine.<br> Die Ergebnismenge kann über die Angabe der VTS eingeschränkt werden.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
         * @summary Endpunkt der die Metadaten des Verfahrensablaufs eines Verfahrens liefert.
         * @param {string} planID PlanID vom Verfahren
         * @param {Array<string>} [codeVts] Liste der Verfahrensteilschritte nach der eingschränkt werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrensteilschritteDetail: async (planID: string, codeVts?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVerfahrensteilschritteDetail', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/verfahrensteilschritte`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codeVts) {
                localVarQueryParameter['codeVts'] = codeVts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Untermenge der Metadaten des Verfahrensablaufs eines Verfahrens geliefert.<br> Es werden nur die wichtigsten Daten für die Balkenanzeige im Cockpit geliefert (beinhaltet auch die Anzahl der Stellungnahmen)</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
         * @summary Endpunkt der eine Untermenge von Metadaten des Verfahrensablaufs eines Verfahrens liefert.
         * @param {string} planID PlanID vom Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrensteilschritteUebersicht: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVerfahrensteilschritteUebersicht', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/verfahrensteilschritteuebersicht`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerfahrensteilschrittResourceApi - functional programming interface
 * @export
 */
export const VerfahrensteilschrittResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerfahrensteilschrittResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein neuer Durchgang für einen Verfahrensschritt angelegt.<br> Basis ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Anlegen eines neuen Durchgangs für einen Verfahrensschritt.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensschritt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDurchgangVs(planID: string, code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrensteilschrittDetailRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDurchgangVs(planID, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensteilschrittResourceApi.createDurchgangVs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt kann ein neuer Durchgang eines Verfahrensteilschritts angelegt werden.<br> Basis ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Anlegen eines neuen Durchgangs eines Verfahrensteilschritts.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensteilschritt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDurchgangVts(planID: string, code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrensteilschrittDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDurchgangVts(planID, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensteilschrittResourceApi.createDurchgangVts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein Durchgang eines Verfahrensteilschritts gelöscht.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Löschen eines Durchgangs aus einem Verfahrensteilschritt.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensteilschritt
         * @param {number} durchgangsnummer Durchgangsnummer (beginnt mit 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDurchgangVts(planID: string, code: string, durchgangsnummer: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDurchgangVts(planID, code, durchgangsnummer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensteilschrittResourceApi.deleteDurchgangVts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten aller Durchgänge eines Verfahrensteilschrittes ausgeliefert.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
         * @summary Endpunkt der die Metadaten aller Durchgänge eines Verfahrensteilschritts liefert.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensteilschritt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerfahrensteilschrittDetail(planID: string, code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrensteilschrittDetailRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerfahrensteilschrittDetail(planID, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensteilschrittResourceApi.getVerfahrensteilschrittDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten des Verfahrensablaufs eines Verfahrens geliefert.<br> Liefert die Detailinformationen zu allen Verfahrensteilschritten eines Verfahrens.<br> Die zurückgegebenen Daten beinhalten auch die Unterverfahrensteilschritte und Termine.<br> Die Ergebnismenge kann über die Angabe der VTS eingeschränkt werden.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
         * @summary Endpunkt der die Metadaten des Verfahrensablaufs eines Verfahrens liefert.
         * @param {string} planID PlanID vom Verfahren
         * @param {Array<string>} [codeVts] Liste der Verfahrensteilschritte nach der eingschränkt werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerfahrensteilschritteDetail(planID: string, codeVts?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrensteilschrittDetailRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerfahrensteilschritteDetail(planID, codeVts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensteilschrittResourceApi.getVerfahrensteilschritteDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Untermenge der Metadaten des Verfahrensablaufs eines Verfahrens geliefert.<br> Es werden nur die wichtigsten Daten für die Balkenanzeige im Cockpit geliefert (beinhaltet auch die Anzahl der Stellungnahmen)</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
         * @summary Endpunkt der eine Untermenge von Metadaten des Verfahrensablaufs eines Verfahrens liefert.
         * @param {string} planID PlanID vom Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerfahrensteilschritteUebersicht(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrensteilschrittUebersichtRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerfahrensteilschritteUebersicht(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrensteilschrittResourceApi.getVerfahrensteilschritteUebersicht']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VerfahrensteilschrittResourceApi - factory interface
 * @export
 */
export const VerfahrensteilschrittResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerfahrensteilschrittResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein neuer Durchgang für einen Verfahrensschritt angelegt.<br> Basis ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Anlegen eines neuen Durchgangs für einen Verfahrensschritt.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensschritt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDurchgangVs(planID: string, code: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrensteilschrittDetailRest>> {
            return localVarFp.createDurchgangVs(planID, code, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt kann ein neuer Durchgang eines Verfahrensteilschritts angelegt werden.<br> Basis ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Anlegen eines neuen Durchgangs eines Verfahrensteilschritts.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensteilschritt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDurchgangVts(planID: string, code: string, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrensteilschrittDetailRest> {
            return localVarFp.createDurchgangVts(planID, code, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein Durchgang eines Verfahrensteilschritts gelöscht.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Löschen eines Durchgangs aus einem Verfahrensteilschritt.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensteilschritt
         * @param {number} durchgangsnummer Durchgangsnummer (beginnt mit 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDurchgangVts(planID: string, code: string, durchgangsnummer: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteDurchgangVts(planID, code, durchgangsnummer, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten aller Durchgänge eines Verfahrensteilschrittes ausgeliefert.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
         * @summary Endpunkt der die Metadaten aller Durchgänge eines Verfahrensteilschritts liefert.
         * @param {string} planID PlanID vom Verfahren
         * @param {string} code Verfahrensteilschritt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrensteilschrittDetail(planID: string, code: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrensteilschrittDetailRest>> {
            return localVarFp.getVerfahrensteilschrittDetail(planID, code, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten des Verfahrensablaufs eines Verfahrens geliefert.<br> Liefert die Detailinformationen zu allen Verfahrensteilschritten eines Verfahrens.<br> Die zurückgegebenen Daten beinhalten auch die Unterverfahrensteilschritte und Termine.<br> Die Ergebnismenge kann über die Angabe der VTS eingeschränkt werden.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
         * @summary Endpunkt der die Metadaten des Verfahrensablaufs eines Verfahrens liefert.
         * @param {string} planID PlanID vom Verfahren
         * @param {Array<string>} [codeVts] Liste der Verfahrensteilschritte nach der eingschränkt werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrensteilschritteDetail(planID: string, codeVts?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrensteilschrittDetailRest>> {
            return localVarFp.getVerfahrensteilschritteDetail(planID, codeVts, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Untermenge der Metadaten des Verfahrensablaufs eines Verfahrens geliefert.<br> Es werden nur die wichtigsten Daten für die Balkenanzeige im Cockpit geliefert (beinhaltet auch die Anzahl der Stellungnahmen)</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
         * @summary Endpunkt der eine Untermenge von Metadaten des Verfahrensablaufs eines Verfahrens liefert.
         * @param {string} planID PlanID vom Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrensteilschritteUebersicht(planID: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrensteilschrittUebersichtRest>> {
            return localVarFp.getVerfahrensteilschritteUebersicht(planID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerfahrensteilschrittResourceApi - object-oriented interface
 * @export
 * @class VerfahrensteilschrittResourceApi
 * @extends {BaseAPI}
 */
export class VerfahrensteilschrittResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein neuer Durchgang für einen Verfahrensschritt angelegt.<br> Basis ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
     * @summary Endpunkt zum Anlegen eines neuen Durchgangs für einen Verfahrensschritt.
     * @param {string} planID PlanID vom Verfahren
     * @param {string} code Verfahrensschritt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensteilschrittResourceApi
     */
    public createDurchgangVs(planID: string, code: string, options?: RawAxiosRequestConfig) {
        return VerfahrensteilschrittResourceApiFp(this.configuration).createDurchgangVs(planID, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt kann ein neuer Durchgang eines Verfahrensteilschritts angelegt werden.<br> Basis ist die Verfahrenskonfiguration.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
     * @summary Endpunkt zum Anlegen eines neuen Durchgangs eines Verfahrensteilschritts.
     * @param {string} planID PlanID vom Verfahren
     * @param {string} code Verfahrensteilschritt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensteilschrittResourceApi
     */
    public createDurchgangVts(planID: string, code: string, options?: RawAxiosRequestConfig) {
        return VerfahrensteilschrittResourceApiFp(this.configuration).createDurchgangVts(planID, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird ein Durchgang eines Verfahrensteilschritts gelöscht.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN</p>
     * @summary Endpunkt zum Löschen eines Durchgangs aus einem Verfahrensteilschritt.
     * @param {string} planID PlanID vom Verfahren
     * @param {string} code Verfahrensteilschritt
     * @param {number} durchgangsnummer Durchgangsnummer (beginnt mit 1)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensteilschrittResourceApi
     */
    public deleteDurchgangVts(planID: string, code: string, durchgangsnummer: number, options?: RawAxiosRequestConfig) {
        return VerfahrensteilschrittResourceApiFp(this.configuration).deleteDurchgangVts(planID, code, durchgangsnummer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten aller Durchgänge eines Verfahrensteilschrittes ausgeliefert.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
     * @summary Endpunkt der die Metadaten aller Durchgänge eines Verfahrensteilschritts liefert.
     * @param {string} planID PlanID vom Verfahren
     * @param {string} code Verfahrensteilschritt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensteilschrittResourceApi
     */
    public getVerfahrensteilschrittDetail(planID: string, code: string, options?: RawAxiosRequestConfig) {
        return VerfahrensteilschrittResourceApiFp(this.configuration).getVerfahrensteilschrittDetail(planID, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden die Metadaten des Verfahrensablaufs eines Verfahrens geliefert.<br> Liefert die Detailinformationen zu allen Verfahrensteilschritten eines Verfahrens.<br> Die zurückgegebenen Daten beinhalten auch die Unterverfahrensteilschritte und Termine.<br> Die Ergebnismenge kann über die Angabe der VTS eingeschränkt werden.</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
     * @summary Endpunkt der die Metadaten des Verfahrensablaufs eines Verfahrens liefert.
     * @param {string} planID PlanID vom Verfahren
     * @param {Array<string>} [codeVts] Liste der Verfahrensteilschritte nach der eingschränkt werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensteilschrittResourceApi
     */
    public getVerfahrensteilschritteDetail(planID: string, codeVts?: Array<string>, options?: RawAxiosRequestConfig) {
        return VerfahrensteilschrittResourceApiFp(this.configuration).getVerfahrensteilschritteDetail(planID, codeVts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt wird eine Untermenge der Metadaten des Verfahrensablaufs eines Verfahrens geliefert.<br> Es werden nur die wichtigsten Daten für die Balkenanzeige im Cockpit geliefert (beinhaltet auch die Anzahl der Stellungnahmen)</p><p><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN</p>
     * @summary Endpunkt der eine Untermenge von Metadaten des Verfahrensablaufs eines Verfahrens liefert.
     * @param {string} planID PlanID vom Verfahren
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrensteilschrittResourceApi
     */
    public getVerfahrensteilschritteUebersicht(planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrensteilschrittResourceApiFp(this.configuration).getVerfahrensteilschritteUebersicht(planID, options).then((request) => request(this.axios, this.basePath));
    }
}

